class DataStore {
    constructor() {
        /* scrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.docScroll = '';
        this.win = $(window);
        this.body = $('body');

        /* window ++++++++++++++++++++++++++++++++++++++++++*/

        this.docWidth = $(document).width();
        this.docHeight = $(document).height();
        this.winHeight = $(window).height();

        /* non editable ++++++++++++++++++++++++++++++++++++++++++*/
        this.endOfResize = false;

        /* browser ++++++++++++++++++++++++++++++++++++++++++*/

        this.isIe = false;
        this.isFirefox = false;
        this.isSafari = false;
        this.isNoIe = false;
        this.touch = false;
        this.touchBreakPoint = 1199;

        this.mobile = window.matchMedia('(min-width: 0px) and (max-width: 1999px)');

        /* ids ++++++++++++++++++++++++++++++++++++++++++*/
        this.globalIds = JSON.parse(window.wpUrls.globalIds);

        /* fancybox ++++++++++++++++++++++++++++++++++++++++++*/
        this.fancyBoxOnceOpened = false;

        /* masonry ++++++++++++++++++++++++++++++++++++++++++*/
        this.grid = '';

        /* lazyload ++++++++++++++++++++++++++++++++++++++++++*/
        this.lazyLoadInstance = '';

        /* header ++++++++++++++++++++++++++++++++++++++++++*/
        this.didScroll = false;
        this.lastScrollTop = 0;
        this.delta = 100;
        this.headerHeight = $('.header').outerHeight() + 100;
        this.scrollHeader = $('#scrollHeader');

        /* Map ++++++++++++++++++++++++++++++++++++++++++*/
        this.mapMarkers = [];
        this.map = '';

        /* imghero  scroll ++++++++++++++++++++++++++++++++++++++++++*/
        // this.headerScroll = false;
        // this.headerScrollActive = false;
        // this.headerScrollWrap = $('.imgHero .heroImg');
        // this.headerScrollImg = $('.imgHero .blur');
        // this.headerScrollImgBase = $('.imgHero .base');

        // this.headerTextWrap = $('.imgHero .gradient');
        // this.headerTextScroll = $('.imgHero .gradient').length;

        // this.headerScrollEnd = 0;
        // this.headerScrollAmount = 0;
        // this.blurDuplicator = 1.5;
        // this.scrollOpacity = 0;

        /* Popup ++++++++++++++++++++++++++++++++++++++++++*/
        this.lastExit = localStorage.getItem('lastExit');
        this.currentPopupIndex = 0;

        /* archive ++++++++++++++++++++++++++++++++++++++++++*/
        this.dataArray = [];
        this.ajaxActive = false;

        this.counterSwiperInit = false;

        /* Parapllax ++++++++++++++++++++++++++++++++++++++++++*/
        this.parallaxWrap = $('[data-parallaxWrap]');
        this.parallaxImg = this.parallaxWrap.length;
        this.parallaxObject = [];
        this.allowScroll = false;
        this.currentScrollAtWindowBottom = this.docScroll + this.winHeight;
    }
}

export default (new DataStore());
