import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox';
import Swiper, { Navigation, Pagination } from 'swiper';
import { TweenMax, Expo } from 'gsap/all';

// import Masonry from 'masonry-layout';
import mapBox from './inc/hmMaps';
import dataStore from './inc/dataStore';
import { herrlichesMenu, mobileSubmenu, hmNavInit } from './inc/header';
import browserDetection from './inc/browserDetection';
import observeIntersection from './inc/observeIntersection';
import scriptLoader from './inc/scriptLoader';
import devAt from './inc/devAt';

Swiper.use([Navigation, Pagination]);

const LazyLoad = require('vanilla-lazyload');

window.jQuery = jQuery;
window.$ = jQuery;

/* observe element intersection example +++++++++++++++++++++++++++++*/
function initMap() {
    // load map script and initialize map here
    // for testing:
    scriptLoader(
        // 'themeVars' is a localized variable from WordPress
        // eslint-disable-next-line no-undef
        [`${themeVars.assetsUrl}dist/js/mapbox/mapbox-gl.js`],
        mapBox
    );
}
window.initMap = () => { observeIntersection('#map', initMap, true); };

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();

    // console.log(Masonry);

    /* header trans out/in ++++++++++++++++++++++++++++++++++++++++++*/
    setInterval(() => {
        if (dataStore.didScroll) {
            hasScrolled();
            dataStore.didScroll = false;
        }
    }, 250);

    /* hero header ++++++++++++++++++++++++++++++++++++++++++*/

    // if (dataStore.headerScrollImg.length) {
    //     dataStore.headerScroll = true;

    //     // console.log(dataStore.heroVideo);
    //     // if (dataStore.heroVideo.length) {
    //     //     dataStore.heroVideoScroll = true;
    //     // }

    //     headerScrollResize();

    //     observeIntersection('.heroImg', (isIntersecting) => {
    //         if (isIntersecting) {
    //             dataStore.headerScrollActive = true;
    //         } else {
    //             dataStore.headerScrollActive = false;
    //         }
    //     }, false);
    // }

    /* program filter ++++++++++++++++++++++++++++++++++++++++++*/
    if ($('.programBar').length) {
        observeIntersection('.programBar', (isIntersecting, e) => {
            if (isIntersecting) {
                $(e.target).addClass('bg');
            } else {
                $(e.target).removeClass('bg');
            }
        }, false, 0, undefined, '0% 0% -100% 0%');

        $('.programFilterToggle').on('click', (e) => {
            e.preventDefault();
            $(e.currentTarget).next('.filterInnerWrap').slideToggle();
        });
    }

    /* archiv filter ++++++++++++++++++++++++++++++++++++++++++*/
    if ($('#archiveFilterWrap').length) {
        observeIntersection('#archiveFilterWrap', (isIntersecting, e) => {
            if (isIntersecting) {
                $(e.target).addClass('bg');
            } else {
                $(e.target).removeClass('bg');
            }
        }, false, 0, undefined, '0% 0% -100% 0%');
    }

    $('.archiveFilterToggle').on('click', (e) => {
        e.preventDefault();
        $(e.currentTarget).next('aside').slideToggle();
    });

    /* Formular ++++++++++++++++++++++++++++++++++++++++++*/

    $('.gfield input, .gfield textarea, .gfield select').on('focus', (e) => {
        const input = $(e.currentTarget);
        const formField = input.closest('.gfield, span');
        formField.addClass('focus');
    }).on('focusout', (e) => {
        const input = $(e.currentTarget);
        const formField = input.closest('.gfield, span');

        if (!input.val()) {
            formField.removeClass('focus');
        }
    });

    $('.daySelect .gfield_checkbox > div').on('click', (e) => {
        const input = $(e.currentTarget).find('input');

        if (input.is(':checked') === true) {
            input.prop('checked', false);
            input.parent().removeClass('focus');
        } else {
            input.prop('checked', true);
            input.parent().addClass('focus');
        }
    });

    /* popup ++++++++++++++++++++++++++++++++++++++++++*/
    if ($('#popupWrapper').length) {
        let showNow = false;

        if (dataStore.lastExit !== null) {
            const daysOffset = 14;
            const nextPopupShow = new Date(dataStore.lastExit);

            nextPopupShow.setDate(nextPopupShow.getDate() + daysOffset);

            const now = new Date();

            if (now > nextPopupShow) {
                showNow = true;
            }
        } else {
            showNow = true;
        }

        if (showNow === true) {
            if ($('#popupWrapper .popup').length > 1) {
                checkPopupNumber();
            }
            openPopup(0);
        }

        $('.popupFooter .btn').on('click', (e) => {
            e.preventDefault();
            Fancybox.close();
        });
    }

    function checkPopupNumber() {
        let popupcount = 0;

        $('#popupWrapper .popup').each((i, el) => {
            if (checkPopupDates($(el)) === true) {
                popupcount++;
                dataStore.currentPopupIndex++;
                $(el).find('.popupCurrent').text(dataStore.currentPopupIndex);
            }
        });

        $('.popupTotal').text(popupcount);
    }

    function checkPopupDates(popup) {
        const start = popup.data('start');
        const end = popup.data('end');

        // console.log(start);
        // console.log(end);

        let showPopup = true;

        if (start !== null && end !== null && start !== '' && end !== '') {
            const startDate = new Date(start);
            const endDate = new Date(end);

            // console.log(startDate);
            // console.log(endDate);

            const now = new Date();

            if (now >= startDate && now <= endDate) {
                showPopup = true;
            } else {
                showPopup = false;
            }
        }

        return showPopup;
    }

    function openPopup(i) {
        console.log('hier', i);
        const popup = $(`#popup${i}`);

        if (popup.length) {
            // const start = popup.data('start');
            // const end = popup.data('end');

            // // console.log(start);
            // // console.log(end);

            // let showPopup = true;

            // if (start !== null && end !== null && start !== '' && end !== '') {
            //     const startDate = new Date(start);
            //     const endDate = new Date(end);

            //     console.log(startDate);
            //     console.log(endDate);

            //     const now = new Date();

            //     if (now >= startDate && now <= endDate) {
            //         showPopup = true;
            //     } else {
            //         showPopup = false;
            //     }
            // }

            if (checkPopupDates(popup) === true) {
                const fancybox = Fancybox.show([{ src: `#popup${i}`, type: 'inline' }]);

                fancybox.on('destroy', () => {
                    // console.log('done!');
                    // console.log(i);
                    openPopup(parseInt(i + 1, 10));
                });
            } else {
                openPopup(parseInt(i + 1, 10));
            }
        } else {
            localStorage.setItem('lastExit', new Date());
        }
    }

    /* countdown ++++++++++++++++++++++++++++++++++++++++++*/

    if ($('.countdown').length) {
        const countdown = $('.countdown');
        const d = countdown.find('.d');
        const h = countdown.find('.h');
        const m = countdown.find('.m');
        const s = countdown.find('.s');
        const to = countdown.data('to');
        const countDownDate = new Date(to).getTime();

        // Update the count down every 1 second
        const x = setInterval(() => {
            // Get today's date and time
            const now = new Date().getTime();

            // Find the distance between now and the count down date
            const distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            d.text(days);
            h.text(hours);
            m.text(minutes);
            s.text(seconds);

            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                d.text('0');
                h.text('0');
                m.text('0');
                s.text('0');
            }
        }, 1000);
    }

    /* Aufbaubedingungen ++++++++++++++++++++++++++++++++++++++++++*/

    $('.conditionsSearch').on('change keydown paste input', (e) => {
        const searchVal = $(e.currentTarget).val();
        const conditions = $(e.target).closest('.conditions').find('.conditionsContent li');

        conditions.each((i, el) => {
            const content = $(el).text().toLowerCase();

            const hasText = content.indexOf(searchVal.toLowerCase()) !== -1;

            if (hasText) {
                $(el).show();
            } else {
                $(el).hide();
            }
        });
    });

    /* faq ++++++++++++++++++++++++++++++++++++++++++*/

    $('.faqSearch').on('change keydown paste input', (e) => {
        const searchVal = $(e.currentTarget).val();
        const wrap = $(e.target).closest('.filterSection');
        const faqs = wrap.find('.toggleItem.searchItem');
        const noResults = wrap.find('.noResults');

        let hasResults = false;

        faqs.each((i, el) => {
            const content = $(el).text().toLowerCase();

            const hasText = content.indexOf(searchVal.toLowerCase()) !== -1;

            if (hasText) {
                $(el).show();
                hasResults = true;
            } else {
                $(el).hide();
            }
        });

        if (hasResults === true) {
            noResults.removeClass('vis');
        } else {
            noResults.addClass('vis');
        }
    });

    $('.resetSearch').on('click', (e) => {
        e.preventDefault();
        const search = $(e.currentTarget).closest('.filterSection').find('input[type=search]');
        search.val('').trigger('change');
    });

    /* Filter ++++++++++++++++++++++++++++++++++++++++++*/
    if ($('.filterList:not(.ajax) li').length) {
        const filter = getUrlParameter('filter');

        if (filter !== undefined) {
            const el = $(`.filterList li[data-term=${filter}]`);
            filterContent(el);
        }
    }

    if ($('.filterList.ajax li').length) {
        const sPageURL = window.location.search.substring(1);

        if (sPageURL) {
            const sURLVariables = sPageURL.split('&');

            for (let i = 0; i < sURLVariables.length; i++) {
                const element = sURLVariables[i];
                const myArray = element.split('=');
                const values = myArray[1].split(',');

                for (let a = 0; a < values.length; a++) {
                    $(`.filterList[data-type="${myArray[0]}"] li[data-term="${values[a]}"]`).addClass('active')
                        .find('.btn').addClass('active');
                }

                $(`.filterAll[data-type="${myArray[0]}"]`).removeClass('active');
            }

            filterString();
            getArchivePosts(1, $('#filteredArchive').data('posttype'));
        }
    }

    $('.filterAll').on('click', (e) => {
        e.preventDefault();
        const btn = $(e.currentTarget);

        if (!btn.hasClass('active')) {
            btn.addClass('active');
            const type = btn.data('type');

            $(`.filterList[data-type="${type}"] li.active`).removeClass('active');
            $(`.filterList[data-type="${type}"] li .btn.active`).removeClass('active');
            filterString();

            getArchivePosts(1, $('#filteredArchive').data('posttype'));
        }
    });

    $('.filterList li').on('click', (e) => {
        e.preventDefault();
        const el = $(e.currentTarget);
        const ajax = el.parent().hasClass('ajax');

        if (ajax === true && dataStore.ajaxActive === false) {
            const btn = el.find('.btn');

            if (el.hasClass('active')) {
                el.removeClass('active');
                btn.removeClass('active');
            } else {
                el.addClass('active');
                btn.addClass('active');
            }

            filterString();
            getArchivePosts(1, $('#filteredArchive').data('posttype'));
        } else {
            filterContent(el);
        }

        if ($('.faqSearch').length && $('.faqSearch').val() !== '') {
            $('.faqSearch').trigger('change');
        }
    });

    $(document).on('click', '.resetFilter', (e) => {
        e.preventDefault();
        dataStore.dataArray = [];
        window.history.pushState('', '', window.location.origin + window.location.pathname);
        $('.filterList li.active .btn').removeClass('active');
        $('.filterList li.active').removeClass('active');
        getArchivePosts(1);
    });

    $(document).on('click', '.ajaxPageNav a', (e) => {
        e.preventDefault();

        if (dataStore.ajaxActive === false) {
            const number = $(e.currentTarget).text();
            getArchivePosts(number, $('#filteredArchive').data('posttype'));
        }
    });

    /* header search ++++++++++++++++++++++++++++++++++++++++++*/

    window.addEventListener('click', (e) => {
        if ($('.searchBox').hasClass('open')) {
            if (!document.getElementById('searchWrapper').contains(e.target)
            && !document.getElementById('searchWrapperScroll').contains(e.target)) {
                $('.searchBox').removeClass('open');
            }
        }
    });

    $('.searchToggle').click((e) => {
        if ($(e.currentTarget).hasClass('closed')) {
            e.preventDefault();

            $(e.currentTarget).removeClass('closed');
            $(e.currentTarget).parent().toggleClass('open');

            setTimeout(() => {
                $(e.currentTarget).parent().find('input').focus();
            }, 300);
        } else if (!$(e.currentTarget).parent().find('input').val()) {
            e.preventDefault();
            $(e.currentTarget).addClass('closed');
            $(e.currentTarget).parent().removeClass('open');
        }
    });

    /* accordion ++++++++++++++++++++++++++++++++++++++++++*/

    $('.toggleTrigger').on('click', (e) => {
        e.preventDefault();
        const toggleItem = $(e.currentTarget).closest('.toggleItem');
        toggleItem.toggleClass('open');
        toggleItem.find('.toggleContent').slideToggle();
    });

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    hmNavInit();

    /* Intersection Observer  ++++++++++++++++++++++++++++++++++++++++++*/
    observeIntersection('.fadeIn', (isIntersecting, e) => {
        if (isIntersecting) {
            $(e.target).not('.vis').addClass('vis');
        }
    }, true);

    observeIntersection('.fadeInRow', (isIntersecting, e) => {
        if (isIntersecting) {
            // console.log('fadeInRow');
            $(e.target)
                .find('> div, > li, > p, > span, > h1, > a')
                .not('.vis')
                .each((i, el) => {
                    setTimeout(() => {
                        $(el).addClass('vis');
                    }, 150 * i);
                });
        }
    }, true);

    observeIntersection('.counters', (isIntersecting, e) => {
        if (isIntersecting) {
            const counters = $(e.target);
            const countNumber = counters.find('.count__number');

            for (let countI = 0; countI < countNumber.length; countI++) {
                const count = countNumber[countI];
                const countedNumber = $(count).attr('data-count');
                const parent = $(count).parent();

                setTimeout(() => {
                    $({ num: 0 }).animate({ num: countedNumber }, {
                        duration: 2500,
                        easing: 'swing',
                        step: (num) => {
                            $(count).html(Math.floor(num));
                        },
                        complete: () => {
                            $(count).html(countedNumber);
                            parent.addClass('finished');
                        }
                    });
                }, 150 * countI);
            }
        }
    }, true);

    observeIntersection('.conditionsContent > li', (isIntersecting, e) => {
        if (isIntersecting) {
            const index = $(e.target).index();
            $('.conditionsMenu > li').removeClass('current');
            $('.conditionsMenu > li').eq(index).addClass('current');
        } else {
            const index = $(e.target).index();
            $('.conditionsMenu > li').eq(index).removeClass('current');
        }
    }, false, 0, undefined, '0% 0% -100% 0%');

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    lazyloadUpdate();

    /* Ffancybox  ++++++++++++++++++++++++++++++++++++++++++*/
    Fancybox.bind(
        '.wp-block-gallery a[href$=jpg], .wp-block-gallery a[href$=png], .wp-block-gallery a[href$=webp]',
        {
            groupAll: true,
            thumbs: false
        },
    );

    Fancybox.bind(
        '.wp-block-image a[href$=jpg], .wp-block-image a[href$=png], .wp-block-image a[href$=webp]'
    );

    /**** Map Box ***/
    // if ($('#map').length) {
    //     mapBox();
    // }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll(() => {
        dataStore.didScroll = true;
        dataStore.docScroll = $(window).scrollTop();
        dataStore.currentScrollAtWindowBottom = dataStore.docScroll + dataStore.winHeight;

        // headerScroll();
        parallaxScroll();
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    $(window).trigger('scroll');

    // headerScrollResize();

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
});

function afterResize() {
    mobileSubmenu();
    herrlichesMenu.destroy();
    herrlichesMenu.init();
    counterSwiper();
    parallaxObject();
}

$(window).on('load', () => {
    parallaxObject();

    /* Swiper  ++++++++++++++++++++++++++++++++++++++++++*/
    counterSwiper();

    // new Swiper('.headerSlider', {
    //     // Optional parameters
    //     loop: true,
    //     navigation: {
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev',
    //     },
    // });

    $('.news').each((i, el) => {
        const parentId = $(el).attr('id');
        const newsSlider = new Swiper(`#${parentId} .newsSlider`, {
            // Optional parameters
            loop: false,
            simulateTouch: true,
            slidesPerView: 'auto',
            on: {
                init: () => {
                    // $(e.el).addClass('vis');
                    lazyloadUpdate();
                }
            },
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev',
            // },
        });

        newsSlider.on('slideChange', () => {
            lazyloadUpdate();
        });
    });

    $('.history').each((i, el) => {
        const parentId = $(el).attr('id');
        const slides = $(`#${parentId} .swiper-slide`);
        const slideCount = slides.length;
        const progressWrap = $(`#${parentId} .progressWrap`);
        const progressWrapWidth = progressWrap.width();
        const progressCircle = $(`#${parentId} .progressCircle`);

        const historySwiper = new Swiper(`#${parentId} .historySwiper`, {
            slidesPerView: 1,
            loop: false,
            centeredSlides: false,
            autoHeight: true,
            pagination: {
                el: `#${parentId} .swiper-pagination.progressbar`,
                type: 'progressbar',
            },
            navigation: {
                nextEl: `#${parentId} .swiper-button-next`,
                prevEl: `#${parentId} .swiper-button-prev`,
            },
            on: {
                init: () => {
                    lazyloadUpdate();
                    const index = 1;

                    progressCircle.css({
                        transform: `translate(${(progressWrapWidth / slideCount) * index}px,0px)`,
                        '-webkit-transform': `translate(${(progressWrapWidth / slideCount) * index}px,0px)`
                    });
                }
            }
        });

        historySwiper.on('slideChange', () => {
            lazyloadUpdate();
            const index = historySwiper.realIndex + 1;

            progressCircle.css({
                transform: `translate(${(progressWrapWidth / slideCount) * index}px,0px)`,
                '-webkit-transform': `translate(${(progressWrapWidth / slideCount) * index}px,0px)`
            });
        });
    });

    /* developed at ++++++++++++++++++++++++++++++++++++++++++*/
    devAt();
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }
};

/* Webp Support Detection  ++++++++++++++++++++++++++++++++++++++++++*/
async function supportsWebp() {
    if (!window.self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

// Add Webp to Background Imgaes when supported
(async() => {
    if (await supportsWebp()) {
        dataStore.body.addClass('webp');
        const lazyBg = document.querySelectorAll('[data-bg]');
        lazyBg.forEach((element) => {
            let { bg } = element.dataset;
            if (bg.match(/.*(.jpg|.jpeg|.png)/) && !bg.includes('.webp') && !bg.includes('cdninstagram')) {
                bg = `${bg}.webp`;
                element.setAttribute('data-bg', bg);
            }
        });
    }

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });
    lazyloadUpdate();
})();

// function headerScroll() {
//     if (dataStore.headerScrollActive && dataStore.headerScroll) {
//         dataStore.scrollOpacity = dataStore.headerScrollAmount * (dataStore.docScroll * dataStore.blurDuplicator);

//         dataStore.headerScrollImg.css({
//             opacity: dataStore.scrollOpacity
//         });

//         dataStore.headerScrollImgBase.css({
//             opacity: 1 - dataStore.scrollOpacity
//         });
//     }
// }

// function headerScrollResize() {
//     if (dataStore.headerScroll) {
//         dataStore.headerScrollEnd = dataStore.headerScrollWrap.height() + dataStore.headerScrollWrap.offset().top;
//         dataStore.headerScrollAmount = 1 / dataStore.headerScrollEnd;
//     }
// }

const getUrlParameter = function getUrlParameter(sParam) {
    const sPageURL = window.location.search.substring(1);
    const sURLVariables = sPageURL.split('&');
    let sParameterName;
    let param;
    let i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            param = sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }

    return param;
};

function filterContent(el) {
    const btn = el.find('.btn');
    const term = el.data('term');

    const filterSection = el.closest('.filterSection');
    const filterItems = filterSection.find('.filterItemList > li, .filterItemList .programmItem ');

    if (el.hasClass('active')) {
        el.removeClass('active');
        btn.removeClass('active');
        filterItems.show().addClass('searchItem');
        window.history.pushState('', '', window.location.origin + window.location.pathname);
    } else {
        window.history.pushState('', '', `${window.location.origin + window.location.pathname}?filter=${term}`);
        $('.filterList li').removeClass('active');
        $('.filterList li .btn').removeClass('active');
        el.addClass('active');
        btn.addClass('active');

        for (let index = 0; index < filterItems.length; index++) {
            const element = filterItems[index];
            const itemTerms = $(element).data('terms').split(',');

            if (itemTerms.indexOf(term) === -1) {
                $(element).hide().removeClass('searchItem');
            } else {
                $(element).show().addClass('searchItem');
            }
        }
    }
}

function filterString() {
    const filterLists = $('.filterList');
    let historyString = '';

    for (let index = 0; index < filterLists.length; index++) {
        const list = $(filterLists[index]);
        const listType = list.data('type');

        const activeItems = list.find('li.active');
        let activeString = '';

        for (let i = 0; i < activeItems.length; i++) {
            if (activeString !== '') {
                activeString += ',';
            }
            activeString += $(activeItems[i]).data('term');
        }
        dataStore.dataArray[listType] = activeString;

        if (activeString === '') {
            $(`.filterAll[data-type="${listType}"]`).addClass('active');
        } else {
            if (historyString !== '') {
                historyString += '&';
            }
            historyString += `${listType}=${activeString}`;
            $(`.filterAll[data-type="${listType}"]`).removeClass('active');
        }
    }

    const path = window.location.pathname;

    window.history.pushState('', '', `${window.location.origin + path}?${historyString}`);
}

function getArchivePosts(page, postType) {
    if (dataStore.dataArray.years === '') {
        dataStore.dataArray.years = 'all';
    }

    $.ajax({
        type: 'GET',
        url: `${window.themeVars.restURL}archive/archiveendpoint`,
        beforeSend: (xhr) => {
            xhr.setRequestHeader('X-WP-Nonce', window.themeVars.restNonce);
            $('.filterPostWrap').empty();
            $('.ajaxLoader').addClass('vis');
            dataStore.ajaxActive = true;
        },
        data: {
            postType,
            years: dataStore.dataArray.years,
            category: dataStore.dataArray.category,
            page
        },
        success: (response) => {
            $('.ajaxLoader').removeClass('vis');

            if (response) {
                // console.log('successfull');
                $('.filterPostWrap').append(response);
                lazyloadUpdate();

                $('.filterPostWrap > div').not('.vis').each((i, item) => {
                    setTimeout(() => {
                        $(item).addClass('vis');
                    }, 150 * i);
                });
            }

            dataStore.ajaxActive = false;
        }
    });
}

function counterSwiper() {
    // console.log('counterSwiper');
    // console.log(dataStore.mobile.matches);

    if (dataStore.mobile.matches) {
        // console.log('geht rein');
        if (!dataStore.counterSwiperInit) {
            // console.log('geht weiter rein');
            dataStore.counterSwiperInit = true;
            $('.counterWrap').each((i, el) => {
                const parentId = $(el).attr('id');
                new Swiper(`#${parentId} .counterSlider`, {
                    loop: false,
                    simulateTouch: true,
                    slidesPerView: 'auto',
                    spaceBetween: 16
                });
            });
        }
    } else {
        $('.counterSlider').each(() => {
            if (this !== undefined) {
                this[0].swiper.destroy();
            }
        });
        dataStore.counterSwiperInit = false;
    }
}

function hasScrolled() {
    // if (dataStore.docWidth >= dataStore.smMax) {
    // Make sure they scroll more than delta
    if (Math.abs(dataStore.lastScrollTop - dataStore.docScroll) <= dataStore.delta) {
        return;
    }

    // If they scrolled down and are past the navbar, add class .nav-up.
    if (dataStore.docScroll > dataStore.lastScrollTop && dataStore.docScroll > dataStore.headerHeight) {
        // Scroll Down
        dataStore.scrollHeader.removeClass('navDown').addClass('scrolled');
    } else if (dataStore.docScroll < (dataStore.headerHeight + 100)) {
        dataStore.scrollHeader.removeClass('navDown');

        if (dataStore.docScroll < dataStore.headerHeight) {
            // top reached
            dataStore.scrollHeader.removeClass('scrolled');
        }
    } else if (dataStore.docScroll + dataStore.winHeight < dataStore.docHeight) {
        // Scroll Up
        dataStore.scrollHeader.addClass('navDown');
    }
    // }
    dataStore.lastScrollTop = dataStore.docScroll;
}

// Parallax Scroll Function
function parallaxScroll() {
    if (dataStore.allowScroll === true && dataStore.docWidth > 767 && dataStore.parallaxImg) {
        for (let a = 0, aLen = dataStore.parallaxObject.length; a < aLen; a++) {
            const transPerPix = dataStore.parallaxObject[a].maxTransPix
            / (dataStore.winHeight + dataStore.parallaxObject[a].elHeight);
            //Faktor je nach Höhe von Bildschirm und IMg im verhältnis.
            let translate = (dataStore.currentScrollAtWindowBottom - dataStore.parallaxObject[a].topEl) * transPerPix;

            if (translate > dataStore.parallaxObject[a].maxTransPix) {
                translate = dataStore.parallaxObject[a].maxTransPix;
            }

            if (dataStore.parallaxObject[a].direction) {
                TweenMax.to(dataStore.parallaxObject[a].el, 1.1, {
                    y: translate,
                    ease: Expo.easeOut,

                    overwrite: 5
                });
            } else {
                TweenMax.to(dataStore.parallaxObject[a].el, 1.1, {
                    y: -translate,
                    ease: Expo.easeOut,
                    overwrite: 5
                });
            }
        }
    }
}

function parallaxObject() {
    //  Parallax Scroll Img  ++++++++++++++++++++++++++++++++++++++++++*/
    if (dataStore.docWidth > 767 && dataStore.parallaxImg) {
        dataStore.parallaxObject = [];
        dataStore.allowScroll = true;
        dataStore.parallaxWrap.each((i, el) => {
            const elHeight = $(el).height();
            const topEl = $(el).offset().top;

            let diretionChanged = false;

            if ($(el).data('direction') === 'down') {
                diretionChanged = true;
            }

            const tempObject = {
                el: $(el),
                elHeight,
                maxTransPix: $(el).data('speed'),
                topEl,
                direction: diretionChanged
            };
            dataStore.parallaxObject.push(tempObject);
        });

        //console.log(dataStore.parallaxObject);
    }
}
